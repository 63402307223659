@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

button{
    border:none;
    background:none;    
}
#surveyWrapper{
    /* margin-top:20%; */
    padding:2rem 0;
    /* max-height:478px; */
}
#moreWrappers{
    height:90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#surveyWrapper h1,#surveyWrapper h2,#surveyWrapper h3,#surveyWrapper h4{
    font-size:1.2rem;
    font-family:'Oswald';
}
form ul li{
    border-bottom: 2px solid #4e99b0;
    padding:.5rem 0;
}
#surveyWrapper ul li input{
    /* margin-left:1.5rem; */
}
#surveyWrapper input{
    margin-right:1rem;
}
.navButtons{
    margin:1.4rem 0;
    /* height:90px; */
    text-align: right;
}
.navButtons button{
    margin:1rem;
}
#a,#b,#c,#d{
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}
#a:checked,#b:checked,#c:checked,#d:checked{
    background-color: #32ba7c;

}
.soucresContainerList li a{
border-bottom:2px solid #d9d9d9;
padding-bottom:.5rem;}
.sourcesContainer{
    /* border:2px solid black; */
    width:94%;
    margin: auto;
}
#previous{
    color:#4e99b0;
    margin-right:.5rem;
}
/* .headerForSources{
margin-left:4%;} */
#spaceMan{
    /* width:330px; */
    height:330px;
    position: absolute;
    top:0px;
    right:0px;
}
#next,.learnMore{ 
    background-color:#4e99b0;
    color:white;
    border-radius: 10px;
}
.navButtons button, .learnMore{
    padding:.4rem .8rem;
    border: 2px solid #4e99b0;
    font-size:1.2rem;
}
button{
    cursor:pointer;
}
#surveyContentWrapper{
    width:80%;
    margin:auto;
    padding-left:10px;
}
/* working on it li text needs to be in p tag */
body{
    font-family:'Source Sans Pro'
}
.resoursesContainer{
display: flex;
/* border:2px solid green; */
justify-content:center;
gap:20px;
text-align: center;
}
.resourcesContainer li{
text-align: center;
background-color:#D9D9D9;
 
}
.soucresContainerList{
display: flex;
/* border:2px solid purple; */
justify-content: center;
flex-wrap: wrap;
gap:20px;
text-align: center;
}
.sourcesContainer li{
    text-align: center;
}
.sourcesContainer a, .soucresContainer a:visited{

}
.learnMore{
    margin-top:1rem;
    font-family:"Fredoka One" !important;
    position: absolute;
    right: 0;
    left:0;
    color:white !important;
    bottom: 0;
}
.sumItUp{
    text-align: center;
}
.grandWrapper{
    width:90%;
    margin:auto;
}
.rokcetIcon{
    width:200px
}
.logo{
    z-index:-4;
}
/* nav{
    display: none !important;
} */
@media screen and (max-width:1370px){
    #spaceMan{
        height: 216px !important;
    }
}
@media screen and (max-width:960px){
    .navButtons,.bottom_space{
      /* margin:.5rem 0 !important; */
    }
    /* .logo{
        z-index: 99;
    } */
    /* .logo{

    } */
  }
@media screen and (max-width:800px){
    .resoursesContainer{
    display: block;
    }
    .resListItems{
    width:90% !important;
    }
    .resListItems:first-of-type{
        margin-bottom:2rem;
    }
    }
  @media screen and (max-width:560px){
    #surveyWrapper{
        width:90%;
    }
    .confirmationContainer{
        margin-top:-1rem;
    }
    /* confirmation needs to move down from this point down */
    /* .App p,.simpleEyeCatcher{
        font-size:15px;
    } */
    /* .simpleEyeCatcher {
    font-size:1rem;
    } */
  }