@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
.App p{
  font-size:18px;
  line-height: 1.8rem;
  color:#4f4f4f;
}
#surveyWrapper{
  border:3px solid black;
  background:white;
  /* testing relative for space man */
  /* position: relative; */
  width:65%; 
  margin:auto;
  max-width: 845px;
  border-radius: 40px;
}
#correctOrWrong{
  /* margin-right */
}
/* not terrible will needs some adjusting */
#manillaFolder{
  position: fixed;
  width:75%;
  margin: auto;
  margin-top:-2.5rem;
  /* shoutout stack overflow */
  left: 0;
  right: 0;
  z-index: -99;
  max-width:945px;
  max-height: 1100px;
}
.margin-left{
  margin-left:1rem;
}
body{
  background-image:url('./Images/stars.jpg');
  background-size:cover;
  /* background-repeat: no-repeat; */
}
#questionCount,.introHeader{
  font-size:2rem !important;
}
#question{
  font-size:1.7rem !important;
}
p,label{
  font-family: 'Roboto' !important;
}
.liWrapper{
  display: inline-block;
}
.simpleEyeCatcher{
  font-size: 1.5rem;
}
#blurb p{
  margin-top:1rem;
}
.icon{
  height:85px;
  margin-top:-2rem;
  width:85px;
  /* position: absolute; */
}
.incorrectAnserw{
  background-color: red;
}
#blurb{
/* border:2px solid black; */
/* height:150px; */
}
.resoursesContainer{
  margin-bottom:2rem;
}
#question{
  /* border:2px solid black; */
  /* height:100px; */
}
#correctOrWrong{
  /* border:2px solid black; */
}
#questionCount,#next,.introHeader,.nextButton{
  font-family:'Fredoka One' !important;
  /* delete below if it does not work out */
  /* position: absolute;
  top:21.5vh; */
}
.space{
  margin: 1.5rem 0;
}
.bottom_space{
  margin-bottom:1.5rem;
}
.logo{
  position: fixed;
  bottom:50px;
  left:50px;
}
.logo img{
  width:330px;
  height:119px;
}
button{
  font-family:'Oswald';
}
.folder_img{
position: fixed;
bottom:5%;
left:15%;
z-index:-99;
width:70%;
}
.folder_img img{
width:100%;
}
.learnMoreDescrip{
  margin-bottom:3rem;
}
.resListItems{
  background-color: #f5f5f5;
  cursor:pointer;
  position: relative;
  padding:1rem;
  align-self: stretch;
  border-radius: 10px;
  width:45%;
  box-shadow: -1px 10px 5px 0px rgba(0,0,0,0.21);
  -webkit-box-shadow: -1px 10px 5px 0px rgba(0,0,0,0.21);
  -moz-box-shadow: -1px 10px 5px 0px rgba(0,0,0,0.21);
}
#surveyWrapper a, #surveyWrapper a:visited{
  color:black;
  text-decoration: none;
  font-family: 'Oswald';
}
form li{
  cursor: pointer;
}
.stat{
  font-size:24px;
  font-weight: bold;
  font-family:"Roboto" !important;
}
.confirmationContainer{
  text-align: center;
  margin-top:-3rem;
}
/* BREAKPOINTS!!! */
@media screen and (max-width:1212px) {
  
  #spaceMan{
    display:none;
  }
}
@media screen and (max-height:680px){
  .logo img{
    display:none;
  }
}
@media screen and (max-width:1010px){
  #surveyWrapper{
    /* padding:.5rem 0 !important; */
    /* min-height: 330px; */
  }
  .resoursesContainer{
    margin-bottom:2rem !important;
  }
  .navButtons,.space{
    /* margin:.5rem 0; */
    /* padding-bottom:2rem; */
  }
  .icon{
    width:65px;
    height:65px;
  }
  .logo{
    display: inline-block;
    position:absolute;
    text-align: center;
    bottom: 30px;
    right: 0;
    left:0;
  }
  .logo img{
    width: 204px;
    height:84px;
  }
}
@media screen and (max-width:840px){
  #manillaFolder{
    display: none !important;
  }
  #surveyWrapper{
    max-height: none !important;
  }
}
/* ANIMATIONS!!! */  
.correctAnserw{
  background-color: #32ba7c;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.5s;
}
.animate-scale{
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.5s;
}
@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}