@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
#landingWrapper{
    max-width:1350px;
    width:90%;
    margin: auto;
}
#landingConclusion{
    clear:left;
}
#hero_img_area{
    background-image: url('./Images/spaceWalk.png');
    background-size:100%;
    background-position:center;
    background-repeat: no-repeat;
    height:500px;
    width: 100%;
    margin: 0rem auto 1rem auto;
}
.landingHeader{
    font-size:2rem;
    font-family:'Oswald';
    margin:2rem 0 1rem 0;
    line-height: normal;
}
.landingConHeader{
    font-size:2rem;
    font-family:'Oswald';
    margin:0 0 1rem 0;
}
#visitUs{
    text-align: center;
    margin-top:1rem;
}
#visitUs a,#visitUs a:visited,.color_blue,.color_blue:visited{
color:#3985ff
}
.introSurveyWrapper{
    position: relative;
}
.landingSubHeader{
    font-size:1.5rem;
    font-family: 'Oswald';
    margin:.6rem 0;
}
.landingSuperHeader{
    font-size: 3.5rem;
    font-family: 'Oswald';
    color:#3985ff;
    margin: .5rem 0 1rem 0;
}
#moreWrappers{
    height:90vh;
    /* display: block; */
}
a,a:visited{
    color:black;
    text-decoration: none;
}
#SSELogo{
    width:80%;
}
.floatRight img,.floatLeft img,.sidePanelImg{
    border-radius: 10px;
}
#landing_main_section{
width: 86%;
border-radius: 10px;
max-width:975px;
background-color:white;
margin-right: 1rem;
padding:1rem;
}
#sidebar{
    border-radius: 10px;
    color:black;
    width:25%;
    padding:1rem;
    background-color: white;
}
.wrapper_with_all_content{
color:black;
display: flex;
justify-content: space-between;
/* gap:40px; */
}
.floatLeft .caption,.floatRight .caption{
    color:white;
    font-size:12px;
    line-height: normal;
    padding:.8rem;
    background-color:#292929;
    /* margin-top:-10px; */
    border-radius: 10px;
    /* margin: 0; */
}
.imgOne{
    margin:6rem 1rem 1rem 1rem;
}
.floatRight{
    float: right;
  width:371px;
  /* margin: 1rem; */
}
.floatLeft{
    float: left;
    margin:2.4rem 1rem 2rem 0;
    width:371px;
}
/* circle images */
.circleContainer{
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.circleImage{
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}
/* NAV */
nav{
    background-color: #ffffff;
    padding:1rem 0;
}
.navContainer{
    width:80%;
    max-width:1350px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.navLogo{
    width:275px;
    height:auto;
}
nav button{
    color:white;
    background-color:black;
    padding:1.5rem 3rem;

    border-radius:10px;
    font-family:'Fredoka One';
    align-self: center;
    position: relative;
}
#call_to_action_link{
    color:white;
}
nav button a{
    /* background-color:black; */
    display: inline-block;     
    position: relative;    
    z-index: 1;     
    padding: 2em;     
    margin: -2em;
    /* color:white !important; */
}
.CTA_Rocket_Intro{
    position: absolute;
    top: -242px;
    right: -169px;
}
.CTA_Rocket{
    position: absolute;
    top: -11px;
    right: -69px;
}
.CTA_Rocket img{
    height:90px;  
}
@media screen and (max-width:1500px){
    #spaceMan{
        height:249px;
    }
}
/* END OF NAV */
@media screen and (max-width:922px){
    #hero_img_area{
        height:350px;
    }
    .CTA_Rocket_Intro{
        right:64px;
    }
}
@media screen and (max-width:400px){
    .CTA_Rocket_Intro{
    display: none;
    }
}
@media screen and (max-height:969px){
    .CTA_Rocket_Intro{
        display: none;
        }
}
@media screen and (max-width:637px){
    .navLogo{
        width:135px !important;
    }
    nav button{
        max-width:220px;
    }
   
    .CTA_Rocket{
        right:-18px;
        top:6px !important;
    }
    .navContainer{
        width:90%;
        gap:6px;
    }
    .CTA_Rocket img{
        height:57px !important;
    }
    .floatRight,.floatLeft{
        width:90%;
    }
}
@media screen and (max-width:850px){
    .CTA_Rocket img{
        height:75px;
    }
    .CTA_Rocket{
        top:-18px;
    }
    #hero_img_area{
        height:200px;
    }
    .navLogo{
        width:210px;
        height:auto;
    }
    nav button{
        padding:1rem 2rem;
    }
   .floatRight,.floatLeft{
    float:none;
   }
   .imgOne,.floatLeft{
    margin:1rem auto 1rem auto;
   }
    #landing_main_section{
        margin-right: 0;
    }
    .wrapper_with_all_content{
        justify-content: center;
    }
    #landing_main_section{
        width:88%;
        margin:auto;
    }
    .wrapper_with_all_content{
        display: block;
    }
    #sidebar{
        margin:1rem auto 1rem auto;
        width:88%;
    }
}
.sidePanelImgMobile{
    width:100%; 
}
#mobileSideBar{
    background:white;
/* display: none; */
padding:2rem;
margin-top:1rem;
display: none;
}
#mobileSideBar #sidePanel{
    display: flex;
    gap:50px;
    justify-content: space-around;
}
/* add hover effect */
#mobileSideBar li{
    width:28%;
    border-bottom:none;
}
    /* width will need to chage once i get real images */
.contentImg,.sidePanelImg{
    width:100%;
}
#sidePanel li{
    width:90%;
    margin:auto;
    margin-bottom:2rem;
    text-align: center;
    padding-bottom:1rem;
    border-bottom: 3px solid #efefef;
}
#sidePanel h4{
    text-align: center;
    margin:2rem 0;
}